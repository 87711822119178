.footer-promo {
    text-align: center;
    background-image: url('../img/map.png');
    background-size: cover;
    background-position: top center;
    background-color: #46678d;
    &__title {
        color: #ffffff;
        font-size: 4.8rem;
        font-weight: bold;
        text-align: center;
    }
    &__button {
        margin-top: 3rem;
    }
}

.footer-company {
    width: 8.8rem;
    height: 1.8rem;
    display: block;
    background-image: url('../img/xp-soft.svg');
    background-size: cover;
}

.footer-text {
    display: flex;
    font-size: 1.15rem;
    line-height: 1.48;
    a {
        color: inherit;
        text-decoration: none;
    }
    & + & {
        margin-top: 2rem;
    }
}

.footer-copyright {
    margin-bottom: 2rem;
    @media (min-width: $screen-md) {
        margin-bottom: 0;
    }
}

.footer-links {
    padding-top: 6rem;
    padding-bottom: 6rem;
}

.footer-finance-menu {
    margin-top: 2rem;
    @media (min-width: $screen-md) {
        margin-top: 0;
        text-align: right;
    }
    
    &__item {
        font-size: 1.2rem;
        line-height: 1.2rem;
        text-decoration: none;
        color: #496a91;
        font-weight: bold;
        display: inline-block;
        & + & {
            margin-left: 3rem;
        }
        @media (min-width: $screen-md) {
            display: block;
            & + & {
                margin-top: 2rem;
                margin-left: 0;
            }
        }
        @media (min-width: $screen-lg) {
            display: inline-block;
            & + & {
                margin-top: 0;
                margin-left: 3rem;
            }
        }
    }
}

.footer-nav {
    display: flex;
    align-items: center;
    &__item {
        & + & {
            margin-left: 3rem;
        }
        a {
            color: #859cbb;
            font-size: 1.2rem;
            line-height: 1.2rem;
            text-decoration: none;
            border-bottom: 1px solid #859cbb;
        }
    }
}

.footer-contacts {
    background-color: #000;
    padding-top: 1.7rem;
    padding-bottom: 1.7rem;
}

.footer-contacts-container {
    @media (min-width: $screen-sm) {
        display: flex;
        justify-content: space-between;
    }
}

.footer-social {
    display: flex;
    &__item {
        display: block;
        width: 2.4rem;
        height: 2.4rem;
        margin-top: 2rem;
        background-size: cover;
        & + & {
            margin-left: 1rem;
        }
        @media (min-width: $screen-sm) {
            margin-top: 0;
        }
    }
    ._facebook {
        background-image: url(../img/social-facebook-square.svg);
        &:hover {
            background-image: url(../img/social-facebook-square-blue.svg);
        }
    }
    ._twitter {
        background-image: url(../img/social-twitter.svg);
        &:hover {
            background-image: url(../img/social-twitter-square-blue.svg);
        }
    }
    ._instagram {
        background-image: url(../img/social-instagram-square.svg);
        &:hover {
            background-image: url(../img/social-instagram-square-blue.svg);
        }
    }
    ._linkedin {
        background-image: url(../img/social-linkedin-square.svg);
        &:hover {
            background-image: url(../img/social-linkedin-square-blue.svg);
        }
    }
    ._youtube {
        background-image: url(../img/social-youtube-square.svg);
        &:hover {
            background-image: url(../img/social-youtube-square-blue.svg);
        }
    }
    ._rss {
        background-image: url(../img/social-rss-square.svg);
        &:hover {
            background-image: url(../img/social-rss-square-blue.svg);
        }
    }
    ._skype {
        background-image: url(../img/social-skype-square.svg);
        &:hover {
            background-image: url(../img/social-skype-square-blue.svg);
        }
    }
    ._google {
        background-image: url(../img/social-googleplus-square.svg);
        &:hover {
            background-image: url(../img/social-googleplus-square-blue.svg);
        }
    }
}

.footer-icon {
    position: relative;
    display: inline-block;
    margin-right: .7rem;
    position: relative;
    background-repeat: no-repeat;
    flex-grow: 0;
    flex-shrink: 0;
    &--address {
        flex-basis: 1.2rem;
        top: .1rem;
        background-image: url('../img/icon-pin.svg')
    }
    &--phone {
        flex-basis: 1.5rem;
        background-image: url('../img/icon-phone.svg')
    }
    &--email {
        flex-basis: 1.6rem;
        top: .2rem;
        background-image: url('../img/icon-mail.svg')
    }
}

.preload {
    position: absolute;
    left: -9999px;
    height: 1px;
    opacity: 0;
}