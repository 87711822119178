.promo {
    background-color: #4c5d7c;
    background-image: url('../img/stock.jpg');
    text-align: center;
    color: #fff;
    padding-top: 80px;
    padding-bottom: 80px;
    &__header {
        @media (min-width: $screen-xl) {
            justify-content: center;
        }
        
    }
    &__footer {
        @media (min-width: $screen-xl) {
            justify-content: center;
        }
    }
    &__title {
        font-weight: bold;
        font-size: 4.8rem;
        line-height: 1.2;
    }
    &__button {
        margin-top: 4rem;
    }
    &__postfix {
        margin-top: 4rem;
        font-weight: bold;
        font-size: 2.4rem;
    }
    &__small {
        font-size: 1.6rem;
        margin-top: 2rem;
    }
}