.btn {
    font-size: 1.3rem;
    line-height: 1.8rem;
    padding: 1.6rem 7rem;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
    letter-spacing: 1px;
    display: inline-block;
    border-radius: 0.4rem;
    background-color: #ff7a00;
    color: #fff;
    border: solid 2px transparent;
    transition: all 200ms ease;
    &:hover {
        border: solid 2px #ffffff;
    }
    &:active {
        background-color: #fff;
        color: #496a91;
    }
    &--small {
        display: inline-block;
        text-transform: none;
        letter-spacing: initial;
        font-weight: normal;
        padding: .9rem 4.5rem; 
        border: solid 1px transparent;
        &:hover {
            border: solid 1px #496a91;
        }
        &:active {
            background-color: #496a91;
            color: #fff;
        }
    }
}