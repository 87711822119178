.map {
    background-color: $color_grey;
    width: 100%;
    padding-top: 35%;
    position: relative;
}

.map-g {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
}