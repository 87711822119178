.form-label {
    font-size: 1.4rem;
    margin-bottom: .5rem;
    display: block;
}

.form-field {
    position: relative;
    & + & {
        margin-top: 2.45rem;
    }
}

.form-message {
    margin-top: 2.45rem;
    @media (min-width: $screen-lg) {
        margin-top: 0;
    }
}

.form-control {
    width: 100%;
    border-radius: 4px;
    box-shadow: inset 0 1px 5px 0 rgba(0, 0, 0, 0.35);
    border: solid 1px #a6a6a6;
    background-color: #fff;
    color: #000;
    font-size: 1.6rem;
    line-height: 2rem;
    padding: 1.4rem 1rem;
    font-family: inherit;
    transition: all 200ms ease;
    outline: none;
    &:focus {
        box-shadow: none;
        border: solid 1px #496a91;
    }
}

.form-field {
    position: relative;
}

.form-submit {
    padding-top: 2rem;
    text-align: right;
}

.form-invalid {
    border: 1px solid red!important;
    box-shadow: inset 0 1px 5px 0 rgba(255, 0, 0, 0.35);
}

.form-error {
    font-size: 22px;
    line-height: 26px;
    color: #fff;
    padding: 2px 4px;
    position: absolute;
    bottom: -13px;
    left: 0;
    background-color: red;   
    @media (min-width: $screen-lg) {
        font-size: 11px;
        line-height: 14px;
        bottom: -10px;
    } 
}

.message-sent {
    display: none;
    padding: 2rem;
    color: #fff;
    text-align: center;
    font-size: 1.6rem;
    font-weight: bold;
    background-color: green;
    margin-bottom: 1rem;    
}

.message-fail {
    display: none;
    padding: 2rem;
    color: #fff;
    text-align: center;
    font-size: 1.6rem;
    font-weight: bold;
    background-color: red;
    margin-bottom: 1rem;    
}