.page-footer {
    display: block;
}

.page-content {
    padding-top: $section_top;
    padding-bottom: $section_bottom;
}

.page-shim {
    background-color: #edf2fb;
    padding-top: $section_top;
    padding-bottom: $section_bottom;
    border-top: dashed 1px $shim_border_color;
}

.page-body {
    @media (min-width: $screen-lg) {
        justify-content: center;
    }
}

.page-split {
    @media (min-width: $screen-lg) {
        justify-content: space-between;
    }
}

.page-adaptive-column {
    & + & {
        margin-top: $adaptive-margin;
    }
    @media (min-width: $screen-md) {
        & + & {
            margin-top: 0;
        }
    }
}