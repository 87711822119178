@mixin h1() {
    font-size: 3.6rem;
    line-height: 3.8rem;
    font-weight: bold;
    margin-bottom: 6.5rem;
    span {
        display: inline-block;
    }
    span + span {
        margin-left: 1rem;
    }
}

.raw {
    h1 {
        @include h1();
    }
    h2 {
        font-size: 2.4rem;
        line-height: 3.2rem;
        font-weight: normal;
        margin-bottom: 60px;
    }
    h3 {
        font-size: 2.4rem;
        line-height: 3.2rem;
        font-weight: normal;
        margin-bottom: 2rem;
    }
    p {
        font-size: 1.4rem;
        line-height: 2rem;
        color: $color_grey;
    }
    h1, h2, h3 {
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.h1-orange {
    color: $color_orange;
}

.h1-orange-dashed {
    border-bottom: 1px solid $color_orange;
}

.h1-black-dashed {
    border-bottom: 1px solid #000000;
}