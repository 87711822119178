.panel {
    height: 100%;
    background-color: #fff;
    &__title {
        margin-bottom: 1.7rem;
        font-size: 2.4rem;
    }
    &__text {
        font-size: 1.4rem;
    }
    &__body {
        padding: 30px;
    }
    &__image {
        padding: 5px 5px 0 5px;
        img {
            max-width: 100%;
            max-height: 26.4rem;
        }
    }
}