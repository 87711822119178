.owl-dot {
    width: .6rem;
    height: .6rem;
    background-color: #a6a6a6;
    border-radius: 50%;
    display: inline-block;
    & + & {
        margin-left: 2rem;
    }
    &.active {
        background-color: #ff7a00;;
    }
}

.owl-dots {
    margin-top: 2rem;
    text-align: center;
}