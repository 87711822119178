.props-item {
    padding-top: 40px;
    &__title {
        font-size: 2rem;
        line-height: 1;
    }
    &__image {
        margin-bottom: 2rem;
        display: block;
        max-width: 100%;
    }
    &__text {
        margin-top: 2rem;
        font-size: 1.3rem;
        color: $color_grey;
    }
    &__content {
        padding-bottom: 40px;
        border-bottom: 1px dashed #a6a6a6;
        &--nodash {
            border-bottom: none;
        }
    }
}