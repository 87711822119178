* {
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
    line-height: 1.42;
}

body {
    font-family: Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    background-color: #fff;
    color: #000;
}