.header {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
}

.header-content {
    display: flex;
    justify-content: space-between;
}

.header-logo {
    display: block;
    width: 21.4rem;
    height: 4.8rem;
    background-image: url('../img/xp-soft-logo.svg');
    background-size: cover;
}

.header-menu {
    display: none;
    align-items: center;
    &__item {
        font-size: 1.3rem;
        line-height: 1.3rem;
        color: #404040;
        padding: 0.6rem 0.9rem;
        text-decoration: none;
        border-bottom: 1px solid transparent;
        transition: all 200ms ease;
        &:hover {
            color: #496a91;
        }
        &--active {
            font-weight: bold;
            border-bottom: 1px solid #496a91;
        }
    }
    @media (min-width: $screen-lg) {
        display: flex;
    }
}

.lang {
    display: flex;
    padding-top: .85rem;
    padding-bottom: .85rem;
    margin-left: 3rem;
    align-items: center;
    position: relative;
    &:hover .lang-container {
        border-bottom: 1px solid #496a91;
    }
    @media (min-width: $screen-lg) {
        border-left: 1px dashed $shim_border_color;
        padding-left: 3rem;
    }
}

.lang-container {
    padding-top: .3rem;
    padding-bottom: .3rem;
    display: flex;
    transition: all 200ms ease;
    border-bottom: 1px solid transparent;
}

.lang-current {
    text-transform: uppercase;
    font-size: 1.3rem;
    line-height: 1;
    font-weight: bold;
    display: inline-block;
    position: relative;
    padding-left: 1rem;
    padding-right: 2rem;
    cursor: pointer;
    &:after {
        position: absolute;
        top: 49%;
        transform: translateY(-50%);
        right: 0;
        width: 6px;
        height: 3px;
        background-image: url('../img/path.svg');
        background-size: cover;
        content: '';
        display: none;
        @media (min-width: $screen-lg) {
            display: block;
        }
    }
}

.lang-flag {
    width: 2.1rem;
    height: 1.5rem;
    background-size: cover;
    display: inline-block;
    &--en {
        background-image: url('../img/us.svg');
    }
    &--cn {
        background-image: url('../img/cn.svg');
    }
    &--fr {
        background-image: url('../img/fr.svg');
    }
    &--ru {
        background-image: url('../img/ru.svg');
    }
}

.lang-menu {
    display: none;
    padding-top: .5rem;
    padding-bottom: .5rem;
    border-radius: .2rem;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.14);
    background-color: #fff;
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 2;
    &__item {
        display: block;
        font-size: 1.3rem;
        line-height: 2.31;
        padding: 0 .9rem;
        color: #404040;
        text-decoration: none;
        transition: all 200ms ease;
        display: flex;
        align-items: center;
        span {
            white-space: nowrap;
        }
        span + span {
            margin-left: 1rem;
        }
        &:hover {
            background-color: rgba(51, 217, 217, 0.8);
        }

    }
}

.header-ham {
    width: 4rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    &__menu {
        width: 100%;
        span {
            display: block;
            width: 100%;
            height: 2px;
            background-color: #000;
            & + span {
                margin-top: 1rem;
            }
        }
    }
    @media (min-width: $screen-lg) {
        display: none
    }
}

.menu {
    display: none;
    padding-bottom: 1.5rem;
    &__item {
        font-weight: bold;
        display: block;
        color: #000;
        text-align: center;
        padding-top: 1.1rem;
        padding-bottom: 1.1rem;
        text-decoration: none;
        font-size: 2rem;
    }
}