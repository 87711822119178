.home-title {
    text-align: center;
    @include h1();
}

.home-services-shortener {
    padding: 0 10%;
    height: 100%;
}

.home-services {
    display: flex;
    flex-direction: column;
    height: 100%;
    &__images {
        margin-left: -.4rem;
        img {
            @media (min-width: $screen-md) {
                margin-top: -.6rem;
                width: 100%;
            }
        }
        &--cards {
            img {
                @media (min-width: $screen-md) {
                    width: 50%;
                }
            }
        }
    }
    &__visual {
        flex: 1 0 auto;
    }
    &__body {
        margin-top: 4rem;
    }
    &__title {
        font-size: 2.3rem;
        margin-bottom: 2rem;
    }
    &__text {
        font-size: 1.4rem;
        line-height: 2rem;
        color: $color_grey;
    }
    &__button {
        margin-top: 3rem;
    }
}

.home-currency {
    padding-top: .2rem;
    padding-bottom: .2rem;
    display: flex;
    align-items: center;
    border-radius: 2px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.14);
    &__flag {
        display: block;
        width: 3.9rem;
        height: 2.8rem;
        background-size: cover;
        &--gb {
            background-image: url('../img/gb.svg')
        }
        &--us {
            background-image: url('../img/us.svg')
        }
        &--eu {
            background-image: url('../img/eu.svg')
        }
        &--jp {
            background-image: url('../img/jp.svg')
        }
    }
    &__text {
        padding-left: 2rem;
        font-size: 1.2rem;
        line-height:1;
        @media (min-width: $screen-lg) {
            padding-left: 3.3rem;
            font-size: 1.4rem;
            line-height:1;
        }
    }
    & + & {
        margin-top: 1.34rem;
    }
}

.home-choose {
    text-align: center;
    img {
        margin-bottom: 2rem;
    }
    &__title {
        font-size: 2.4rem;
        line-height: 1.33;
    }
    &__body {
        margin-top: 2rem;
        font-size: 1.4rem;
        line-height: 1.43;
    }
}

.home-enterprise {
    border-top: 1px dashed $shim_border_color;
    padding-top: $section_top;
    &__image {
        font-size: 0;
        img {
            width: 100%;
        }
    }
}

.home-send {
    text-align: center;
    font-size: 2rem;
    line-height: 1.5;
    text-transform: uppercase;
}

.home-counts {
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: #4f648c;
    background-image: url('../img/know-more.svg');
    background-repeat: no-repeat;
    background-position: top center;
    &__col {
        & + & {
            margin-top: 4rem;
            @media (min-width: $screen-md) {
                margin-top: 0;
            }
        }
    }
    &__media {
        height: 4.8rem;
        display: flex;
        align-items: center;
    }
    &__image {
        display: block;
        margin-left: auto;
        margin-right: auto;
        background-size: cover;
        &--currencies {
            width: 4.8rem;
            height: 3.8rem;
            background-image: url('../img/currencies@3x.png');
        }
        &--clients {
            width: 4.8rem;
            height: 3.6rem;
            background-image: url('../img/clients@3x.png');
        }
        &--countries {
            width: 3.8rem;
            height: 4.8rem;
            background-image: url('../img/countries@3x.png');
        }
    }
    &__title {
        margin-top: 1rem;
        text-align: center;
        color: #fff;
        font-size: 2.4rem;
        line-height: 1.33;
    }
}

.home-counts + .page-shim {
    border-top: none;
    border-bottom: dashed 1px $shim_border_color;
}

.home-solution {
    &__col {
        & + & {
            margin-top: 4rem;
            @media (min-width: $screen-lg) {
                margin-top: 0;
            }
        }
    }
    &__title {
        font-size: 3.6rem;
        line-height: 1.2;
        font-weight: bold;
        margin-bottom: 20px;
        padding-left: 20%;
        position: relative;
        &:before {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            content: '';
        }
        &--testimonial:before {
            width: 4.8rem;
            height: 3.9rem;
            background-image: url('../img/testimonial.svg')
        }
        &--aml:before {
            width: 4rem;
            height: 4.6rem;
            background-image: url('../img/aml.svg')
        }
    }
    &__body {
        font-size: 1.44rem;
        line-height: 1.43;
        color: #404040;
    }
    &__footer {
        margin-top: 20px;
    }
}